

export default {
    translation: {
        "Home": "Home",
        "Bounties": "Bounties",
        "Grants": "Grants",
        "Language": "Language",
        "My Grants": "My Grants",
        "My Bounties": "My Bounties",
        "My Ability": "My Ability",
        "not implemented": "not implemented",
        "Connect Wallet": "Connect Wallet",
        "Log Out": "Log Out",
        "Skills": "Skills",
    }
} as any
